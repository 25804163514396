import { AppBar, Box, Toolbar } from "@mui/material";
import { Link } from "../common/Link";

const PublicLayout = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        alignItems: "stretch",
      }}
    >
      <AppBar position="static">
        <Toolbar variant="dense" disableGutters>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                pl: 2,
                fontWeight: 700,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              CmdAid
            </Box>
            <Box
              sx={{
                pr: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Link>Contact</Link>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {children}
    </Box>
  );
};

export default PublicLayout;
