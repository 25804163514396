import { Typography, Box, LinearProgress } from "@mui/material";

const LoadingPage = () => {
  return (
    <>
      <Typography variant="h1">Loading...</Typography>
      <Box sx={{ height: "5px" }}>
        <LinearProgress />
      </Box>
    </>
  );
};

export default LoadingPage;
