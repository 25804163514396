import {
  Logout as LogoutIcon,
  Check as CheckIcon,
  People as TeamIcon,
  CreditCard as CreditCardIcon,
  Domain as DomainIcon,
  DomainAdd as DomainAddIcon,
} from "@mui/icons-material";
import {
  Divider,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  Menu,
  MenuItem,
} from "@mui/material";
import { Link } from "../common/Link";
import { useAuth } from "../common/useAuth";
import { useNavigate } from "react-router-dom";
import { useCurrentOrg, useOrgs } from "../common/OrgProvider";

const UserMenu = ({ anchorEl, open, onClose }) => {
  const navigate = useNavigate();
  const currentOrg = useCurrentOrg();
  const orgs = useOrgs();

  const { logout } = useAuth();

  const handleLink = (to) => () => {
    onClose();
    navigate(to);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      sx={{ width: 320 }}
      slotProps={{
        paper: {
          style: {
            width: 260,
          },
        },
      }}
    >
      <ListSubheader>Org settings</ListSubheader>
      <Link onClick={handleLink(`/${currentOrg.slug}/settings/profile`)}>
        <MenuItem>
          <ListItemIcon>
            <DomainIcon />
          </ListItemIcon>
          <ListItemText>Profile</ListItemText>
        </MenuItem>
      </Link>
      <Link onClick={handleLink(`/${currentOrg.slug}/settings/team`)}>
        <MenuItem>
          <ListItemIcon>
            <TeamIcon />
          </ListItemIcon>
          <ListItemText>Team members</ListItemText>
        </MenuItem>
      </Link>
      <Link onClick={handleLink(`/${currentOrg.slug}/settings/billing`)}>
        <MenuItem>
          <ListItemIcon>
            <CreditCardIcon />
          </ListItemIcon>
          <ListItemText>Billing</ListItemText>
        </MenuItem>
      </Link>
      <Divider />
      <ListSubheader>Switch orgs</ListSubheader>
      {(orgs.data?.data || []).map((org) => (
        <Link key={org.id} to={`/${org.slug}`}>
          <MenuItem>
            <ListItemIcon>
              {currentOrg.slug === org.slug && <CheckIcon />}
            </ListItemIcon>
            <ListItemText>{org.name}</ListItemText>
          </MenuItem>
        </Link>
      ))}
      <Divider />
      <Link>
        <MenuItem>
          <ListItemIcon>
            <DomainAddIcon />
          </ListItemIcon>
          <ListItemText>Create new org</ListItemText>
        </MenuItem>
      </Link>
      <Divider />
      <Link onClick={logout}>
        <MenuItem>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Link>
    </Menu>
  );
};

export default UserMenu;
