import axios from "axios";
import config from "./config";
import { useAuth } from "./useAuth";

export const client = axios.create({
  baseURL: config.apiURL,
  headers: {
    "Content-Type": "application/json",
  },
});

/**
 * Each of the parameters, method, url, and data can set either when useClient
 * is called or when fetch is called.
 *
 * Each of the parameters can also be a function or a static value. If it is a function,
 * then the function will be called with "values" as the parameter.
 */
export const useClient = (defaultMethod, defaultUrl, defaultData = {}) => {
  const { getToken } = useAuth();

  // const request = (values = {}) => {
  const request = ({ method, url, data, ...values }) => {
    const methodOption = method || defaultMethod;
    const urlOption = url || defaultUrl;
    const dataOption = data || defaultData;

    const interpolatedMethod = (
      typeof methodOption === "function" ? methodOption(values) : methodOption
    ).toUpperCase();
    const interpolatedUrl =
      typeof urlOption === "function" ? urlOption(values) : urlOption;
    const interpolatedData =
      typeof dataOption === "function" ? dataOption(values) : dataOption;

    const requestOptions = {
      method: interpolatedMethod,
      url: interpolatedUrl,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };

    if (interpolatedMethod === "GET") {
      requestOptions.params = interpolatedData;
    } else {
      requestOptions.data = interpolatedData;
    }

    const requestFunction = client.request(requestOptions);
    return requestFunction;
  };

  return request;
};
