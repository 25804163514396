import { Link as MuiLink } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { forwardRef } from "react";

export const Link = forwardRef(({ children, to, onClick, ...props }, ref) => {
  const isExternal = to?.startsWith("http") || to?.pathname?.startsWith("http");
  const link = isExternal ? { href: to, target: "_blank" } : { to };

  const click = async (e) => {
    e?.stopPropagation();
    if (onClick || !to) {
      e.preventDefault();
    }
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <MuiLink
      ref={ref}
      component={isExternal ? undefined : RouterLink}
      onClick={click}
      underline="none"
      {...link}
      {...props}
    >
      {children}
    </MuiLink>
  );
});
