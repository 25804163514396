import { createContext } from "react";
import { useLocalStorage } from "react-use";
import { client } from "./api";
import config from "./config";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useLocalStorage("token", null);
  const isLoggedIn = token !== null;

  /**
   * After a user is redirected back to the app from the SSO provider, a code
   * is provided in the query parameter. This code is then sent to the API,
   * which calls the SSO provider to exchange the code for a token. Once the
   * token is returned from the IdP, it validates the user is logged in. The API
   * then generates a new JTW token and passes it back to the client. This is
   * the JWT token this client can use to authenticate with the API.
   */
  const authFromCode = async (provider, code) => {
    const redirectUrl = `${config.hostname}/auth/${provider}/callback`;
    const tokenResponse = await client.post(`/auth/${provider}/callback`, {
      code,
      redirectUrl,
    });
    await setToken(tokenResponse.data.token);
    return tokenResponse.data;
  };

  /**
   * Generates the URL to redirect the user to the SSO provider to login. This
   * requires calling the API backend which generates the URL.
   */
  const ssoLoginRedirect = async (provider) => {
    const payload = {
      redirectUrl: `${config.hostname}/auth/${provider}/callback`,
    };
    const response = await client.post(`/auth/${provider}/request`, payload);
    const redirectUrl = response.data.authorizationUrl;
    return redirectUrl;
  };

  /**
   * Currently it returns the token; however, it'll need to be updated to
   * validate the token then refresh it if it is expired. And if it can't
   * get the token, it'll need to redirect to the login page.
   */
  const getToken = () => {
    if (!isTokenValid()) {
      logout();
      return null;
    }
    return token;
  };

  const isTokenValid = () => {
    const currentTime = Math.floor(Date.now() / 1000); // Convert to seconds
    const claims = getClaims();
    // Check the expiration time
    if (claims.exp && claims.exp < currentTime) {
      return false; // Token is expired
    }

    return true;
  };

  const getClaims = () => {
    // const token = getToken()
    if (!token) {
      return null;
    }
    const claims = JSON.parse(atob(token.split(".")[1]));
    return claims;
  };

  const logout = () => {
    setToken(null);
  };

  const contextValue = {
    authFromCode,
    ssoLoginRedirect,
    getToken,
    getClaims,
    logout,
    isLoggedIn,
    isTokenValid,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
