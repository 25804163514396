const env = window.location.hostname.includes("cmdaid.com") ? "prod" : "dev";

const config = {
  apiURL: env === "dev" ? "http://localhost:3000/" : "https://api.cmdaid.com/",
  hostname:
    env === "dev"
      ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}`
      : `https://${window.location.hostname}`,
};

export default config;
