import { Box } from "@mui/material";
import { Outlet } from "react-router";
import AppMenu from "./AppMenu";
import AppToolbar from "./AppToolbar";

const Layout = () => {
  const menuWidth = 260;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        alignItems: "stretch",
      }}
    >
      <AppToolbar menuWidth={menuWidth} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          width: "100%",
          alignItems: "stretch",
        }}
      >
        <Box sx={{ width: menuWidth, display: "flex", alignItems: "stretch" }}>
          <Box
            sx={{
              // borderRight: "solid 1px #EEE",
              // backgroundColor: '#FFF',
              width: menuWidth,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
            }}
          >
            <AppMenu />
          </Box>
        </Box>
        <Box sx={{ padding: 3, flexGrow: 1 }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
