import { useAuth } from "./useAuth";
import { useLocalStorage } from "react-use";
import { useParams } from "react-router-dom";
import { useClient } from "./api";
import { useQuery } from "@tanstack/react-query";

export const useOrgs = () => {
  const { isLoggedIn } = useAuth();
  const getOrgsClient = useClient("get", "/orgs");

  const getOrgs = useQuery({
    queryKey: ["orgs"],
    queryFn: getOrgsClient,
    enabled: isLoggedIn,
    cacheTime: 1000 * 60 * 60,
    staleTime: 1000 * 60 * 60,
  });
  return getOrgs;
};

/**
 * This is not the most reliable function.
 *
 * The problem is that the orgs are loaded asynchronously, which means that
 * useOrgs() may not have orgs yet. Or, in the case of org updates, the new org
 * is not yet available before the user navigates to the new org.
 *
 * As such, the useOrgs method has the suspense:true property, which uses the
 * <Suspense> component to wait for the orgs to load.
 *
 * This doesn't fix the problem with org updates, which was implemented to also
 * refresh the cache before navigating to the new org.
 */
export const useCurrentOrg = () => {
  const params = useParams();
  const getOrgs = useOrgs();

  const currentOrg =
    getOrgs.data?.data &&
    getOrgs.data?.data.find((org) => org.slug === params.orgSlug);

  return currentOrg;
};

export const useDefaultOrg = () => {
  const { isLoggedIn } = useAuth();
  const getOrgs = useOrgs();

  const [lastOrgSlug, setLastOrgSlug] = useLocalStorage("lastOrgSlug", null);

  let defaultOrg = null;
  if (isLoggedIn) {
    if (lastOrgSlug) {
      // If the user is logged in and has a last org slug, then redirect to that
      defaultOrg = lastOrgSlug;

      // Once the orgs are loaded, we can check if the last org slug is valid,`
      // if it isn't, then redirect to the first org
      if (
        getOrgs.data?.data &&
        !getOrgs.data?.data?.find((org) => org.slug === lastOrgSlug)
      ) {
        defaultOrg = getOrgs.data?.data[0].slug;
      }
    } else if (getOrgs.data?.data) {
      // If the user is logged in but doesn't have a last org slug, and the orgs
      // are loaded, then we can redirect to the first org.
      defaultOrg = getOrgs.data?.data[0].slug;
    }
  }

  const setDefaultOrg = (slug) => {
    if (slug !== lastOrgSlug) {
      setLastOrgSlug(slug);
    }
  };

  return [defaultOrg, setDefaultOrg];
};
