import { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import theme from "./theme/theme";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { AuthProvider } from "./common/AuthProvider.jsx";
import PrivateRoute from "./common/PrivateRoute.jsx";
import Layout from "./layout/Layout.jsx";
import { useDefaultOrg } from "./common/OrgProvider.jsx";
import { SnackbarProvider } from "notistack";
import PublicLayout from "./layout/PublicLayout.jsx";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import LoadingPage from "./common/LoadingPage.jsx";

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 1000 * 60 * 5 } },
});

/**
 * All the pages are lazy loaded, that is, they are rendered for the first time.
 * While it is loading the <Suspense> component renders the <Loading> component.
 */
const LoginPage = lazy(() => import("./auth/Login"));
const AuthCallbackPage = lazy(() => import("./auth/Callback"));
const CommandListPage = lazy(() => import("./commands/CommandListPage"));
const CommandNewPage = lazy(() => import("./commands/CommandNewPage"));
const CommandEditPage = lazy(() => import("./commands/CommandEditPage"));
const TemplateListPage = lazy(() => import("./templates/TemplateListPage"));
const TemplateNewPage = lazy(() => import("./templates/TemplateNewPage"));
const TemplateEditPage = lazy(() => import("./templates/TemplateEditPage"));
const InquiryListPage = lazy(() => import("./inquiries/InquiryListPage"));
const InquiryDetailsPage = lazy(() => import("./inquiries/InquiryDetailsPage"));
const InquiryNewPage = lazy(() => import("./inquiries/InquiryNewPage"));
const InquiryPublicViewPage = lazy(() =>
  import("./inquiries/InquiryPublicViewPage"),
);
const ResponseListPage = lazy(() => import("./responses/ResponseListPage"));
const ResponseDetailsPage = lazy(() =>
  import("./responses/ResponseDetailsPage"),
);
const SettingsProfilePage = lazy(() =>
  import("./settings/SettingsProfilePage"),
);
const SettingsBillingPage = lazy(() =>
  import("./settings/SettingsBillingPage"),
);
const SettingsTeamPage = lazy(() => import("./settings/SettingsTeamPage"));

const suspend = (component) => (
  <Suspense fallback={<LoadingPage />}>{component}</Suspense>
);

const AppRoutes = () => {
  const [defaultOrg] = useDefaultOrg();

  return (
    <Routes>
      <Route
        path="/login"
        element={
          <PublicLayout>
            <LoginPage />
          </PublicLayout>
        }
      />
      <Route
        path="/auth/:provider/callback"
        element={
          <PublicLayout>
            <AuthCallbackPage />
          </PublicLayout>
        }
      />
      <Route
        path="/inquiries/:inquiryId"
        element={
          <PublicLayout>
            <InquiryPublicViewPage />
          </PublicLayout>
        }
      />
      <Route path="/:orgSlug" element={<PrivateRoute />}>
        <Route path="" element={<Layout />}>
          <Route path="" element={<Navigate to="inquiries" />} />
          <Route path="commands" element={suspend(<CommandListPage />)} />
          <Route path="commands/new" element={suspend(<CommandNewPage />)} />
          <Route
            path="commands/:commandId/edit"
            element={suspend(<CommandEditPage />)}
          />
          <Route path="templates" element={suspend(<TemplateListPage />)} />
          <Route path="templates/new" element={suspend(<TemplateNewPage />)} />
          <Route
            path="templates/:templateId/edit"
            element={suspend(<TemplateEditPage />)}
          />
          <Route path="inquiries" element={suspend(<InquiryListPage />)} />
          <Route path="inquiries/new" element={suspend(<InquiryNewPage />)} />
          <Route
            path="inquiries/awaiting"
            element={suspend(<InquiryListPage />)}
          />
          <Route
            path="inquiries/archived"
            element={suspend(<InquiryListPage />)}
          />
          <Route
            path="inquiries/:inquiryId"
            element={suspend(<InquiryDetailsPage />)}
          />
          <Route path="responses" element={suspend(<ResponseListPage />)} />
          <Route
            path="responses/:responseId"
            element={suspend(<ResponseDetailsPage />)}
          />
          <Route
            path="settings/profile"
            element={suspend(<SettingsProfilePage />)}
          />
          <Route
            path="settings/billing"
            element={suspend(<SettingsBillingPage />)}
          />
          <Route path="settings/team" element={suspend(<SettingsTeamPage />)} />
        </Route>
      </Route>
      <Route
        path="/*"
        element={
          <Navigate
            to={defaultOrg ? `/${defaultOrg}` : "/login"}
            replace={true}
          />
        }
      />
    </Routes>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(
  <>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <BrowserRouter>
              <QueryParamProvider adapter={ReactRouter6Adapter}>
                <AppRoutes />
              </QueryParamProvider>
            </BrowserRouter>
          </AuthProvider>
        </QueryClientProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </>,
);
