import { Navigate, Outlet, useParams } from "react-router";
import { useAuth } from "./useAuth";
import { useCurrentOrg, useDefaultOrg } from "./OrgProvider";
import LoadingWindow from "./LoadingWindow";

const PrivateRoute = () => {
  const { isLoggedIn } = useAuth();
  const params = useParams();
  const [, setDefaultOrg] = useDefaultOrg();
  const currentOrg = useCurrentOrg();

  /**
   * Cache the last org slug. This is used when the user is logged so the user
   * is directed back to the last org they were on.
   */
  if (isLoggedIn && params.orgSlug) {
    setDefaultOrg(params.orgSlug);
  }

  return !currentOrg ? (
    <LoadingWindow />
  ) : isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace={true} />
  );
};

export default PrivateRoute;
