import { Avatar, AppBar, Box, Toolbar, Breadcrumbs } from "@mui/material";
import { useAuth } from "../common/useAuth";
import { Home as HomeIcon } from "@mui/icons-material";
import { useState } from "react";
import UserMenu from "./UserMenu";
import { Link } from "../common/Link";
import { useCurrentOrg } from "../common/OrgProvider";
import { matchPath, useLocation } from "react-router-dom";

const AppToolbar = ({ menuWidth }) => {
  const { getClaims } = useAuth();
  const currentOrg = useCurrentOrg();
  const claims = getClaims();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const location = useLocation();

  const breadcrumbMap = [
    {
      path: "/:orgSlug/settings/profile",
      crumbs: [{ title: "Settings" }, { title: "Profile" }],
    },
    {
      path: "/:orgSlug/settings/team",
      crumbs: [{ title: "Settings" }, { title: "Team" }],
    },
    {
      path: "/:orgSlug/settings/billing",
      crumbs: [{ title: "Settings" }, { title: "Billing" }],
    },
    { path: "/:orgSlug/commands", crumbs: [{ title: "Commands" }] },
    {
      path: "/:orgSlug/commands/new",
      crumbs: [
        { title: "Commands", link: `${currentOrg.slug}/commands` },
        { title: "New" },
      ],
    },
    { path: "/:orgSlug/templates", crumbs: [{ title: "Templates" }] },
    {
      path: "/:orgSlug/templates/new",
      crumbs: [
        { title: "Templates", link: `${currentOrg.slug}/templates` },
        { title: "New" },
      ],
    },
    {
      path: "/:orgSlug/templates/:templateId/edit",
      crumbs: [
        { title: "Templates", link: `${currentOrg.slug}/templates` },
        { title: "Edit" },
      ],
    },
    { path: "/:orgSlug/inquiries", crumbs: [{ title: "Inquiries" }] },
    {
      path: "/:orgSlug/inquiries/awaiting",
      crumbs: [
        { title: "Inquiries", link: `${currentOrg.slug}/inquiries` },
        { title: "Awaiting response" },
      ],
    },
    {
      path: "/:orgSlug/inquiries/archived",
      crumbs: [
        { title: "Inquiries", link: `${currentOrg.slug}/inquiries` },
        { title: "Archived" },
      ],
    },
    {
      path: "/:orgSlug/inquiries/new",
      crumbs: [
        { title: "Inquiries", link: `${currentOrg.slug}/inquiries` },
        { title: "New" },
      ],
    },
    {
      path: "/:orgSlug/inquiries/:templateId",
      crumbs: [
        { title: "Inquiries", link: `${currentOrg.slug}/inquiries` },
        { title: "Details" },
      ],
    },
    { path: "/:orgSlug/responses", crumbs: [{ title: "Responses" }] },
    {
      path: "/:orgSlug/responses/:responseId",
      crumbs: [
        { title: "Responses", link: `${currentOrg.slug}/responses` },
        { title: "Details" },
      ],
    },
  ];

  const breadcrumb = breadcrumbMap.find((m) =>
    matchPath(m.path, location.pathname),
  )?.crumbs;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static">
      <Toolbar variant="dense" disableGutters>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: menuWidth,
              pl: 2,
              fontWeight: 700,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            CmdAid
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              pl: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Breadcrumbs separator="›">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {currentOrg?.name}
              </Box>
              {(breadcrumb || []).map((crumb, index) =>
                crumb.link ? (
                  <Link to={crumb.link} key={index}>
                    <Box key={index}>{crumb.title}</Box>
                  </Link>
                ) : (
                  <Box key={index}>{crumb.title}</Box>
                ),
              )}
            </Breadcrumbs>
          </Box>
          <Box
            sx={{
              pr: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Link onClick={handleClick}>
              <Avatar src={claims.profileUrl} sx={{ width: 32, height: 32 }} />
            </Link>
            <UserMenu anchorEl={anchorEl} open={open} onClose={handleClose} />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default AppToolbar;
