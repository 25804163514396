import { Box, LinearProgress, Paper, Typography } from "@mui/material";

const LoadingWindow = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        alignItems: "stretch",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          sx={{
            p: 4,
            mb: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            border: "solid 1px #eee",
            width: "400px",
          }}
          elevation={0}
        >
          <Typography variant="h1" sx={{ mb: 4 }}>
            Loading...
          </Typography>
          <LinearProgress sx={{ width: "100%" }} />
        </Paper>
      </Box>
    </Box>
  );
};

export default LoadingWindow;
