import { createTheme } from "@mui/material";

import "@fontsource/inter";

import "./index.css";

const theme = createTheme({
  // shadows: ["none"],
  palette: {
    // primary: {
    //   main: "#f72585",
    // },
    secondary: {
      main: "#4361ee",
    },
    // action: {
    //   selected: "#FF00FF",
    // },
    text: {
      primary: "#000",
      secondary: "#666",
    },
  },
  typography: {
    fontFamily: "Inter, Helvetica, Arial, sans-serif",
    h1: {
      fontSize: "1.6rem",
      fontWeight: 700,
      marginBottom: "0.8rem",
      // lineHeight: 1.2
    },
    h2: {
      fontSize: "1.2rem",
      fontWeight: 700,
      // lineHeight: 1.2
    },
    h3: {
      fontSize: "1.0rem",
      fontWeight: 700,
      // lineHeight: 1.2
    },
    h4: {
      fontSize: "0.9rem",
      fontWeight: 700,
      // lineHeight: 1.2
    },
    body1: {
      fontSize: "0.9rem",
      fontWeight: 400,
      // lineHeight: 1.2
    },
    caption: {
      fontSize: "0.75rem",
      color: "#666",
      // color: (theme) => theme.palette.text.secondary,
    },
    code: {
      fontFamily: "monospace",
      fontSize: "12px",
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFF",
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "none",
        color: "inherit",
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          borderColor: "#EEE",
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: false,
        size: "small",
      },
      styleOverrides: {
        root: {
          color: "#000",
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: false,
      },
      styleOverrides: {
        root: {
          color: "#000",
          fontWeight: 400,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          background: "linear-gradient(135deg,  #f72585 1%,#4361ee 100%)",
        },
        outlined: {
          background: "#FFF",
        },
        root: {
          boxShadow: "none",
          textTransform: "none",
          paddingLeft: "3rem",
          paddingRight: "3rem",
          fontWeight: 700,
          borderRadius: 8,
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          backgroundColor: "inherit",
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          color: "#000",
          border: "solid 1px #EEE",
          borderRadius: 0,
        },
      },
    },
  },
});

export default theme;
