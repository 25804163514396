import {
  Terminal as TerminalIcon,
  Inbox as InboxIcon,
  FeedOutlined as TemplateIcon,
  Send as SendIcon,
  ScheduleSendOutlined as SentWaitingIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  MenuList,
} from "@mui/material";
import { Link } from "../common/Link";
import { matchPath, useLocation, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { useCurrentOrg } from "../common/OrgProvider";

const matches = (path, pathname, options = {}) => {
  const match = matchPath(
    {
      path,
      exact: false,
      strict: false,
      ...options,
    },
    pathname,
  );
  return match ? true : false;
};

const MyMenuItem = styled(MenuItem)(() => ({
  "&.Mui-selected": {
    borderRadius: "0 20px 20px 0",
    marginRight: "1rem",
  },
  "&:hover": {
    borderRadius: "0 20px 20px 0",
    marginRight: "1rem",
  },
}));

const AppMenu = () => {
  const { pathname } = useLocation();
  const params = useParams();
  const currentOrg = useCurrentOrg();

  return (
    <>
      <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        <Link to={`/${currentOrg.slug}/inquiries/new`}>
          <Button
            variant="contained"
            sx={{ mx: 2, mt: 3, mb: 0, py: 1, borderRadius: 10 }}
            endIcon={<SendIcon />}
          >
            Send Inquiry
          </Button>
        </Link>
        <ListSubheader>Inquiries</ListSubheader>
        <MenuList>
          <Link to={`/${params.orgSlug}/inquiries`}>
            <MyMenuItem
              selected={
                matches("/:orgId/inquiries/*", pathname) &&
                !matches("/:orgId/inquiries/awaiting", pathname) &&
                !matches("/:orgId/inquiries/archived", pathname)
              }
            >
              <ListItemIcon>
                <SendIcon />
              </ListItemIcon>
              <ListItemText>All Inquiries</ListItemText>
            </MyMenuItem>
          </Link>
          <Link to={`/${params.orgSlug}/responses`}>
            <MyMenuItem selected={matches("/:orgId/responses/*", pathname)}>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText>Responses</ListItemText>
            </MyMenuItem>
          </Link>
          <Link to={`/${params.orgSlug}/inquiries/awaiting`}>
            <MyMenuItem
              selected={matches("/:orgId/inquiries/awaiting", pathname)}
            >
              <ListItemIcon>
                <SentWaitingIcon />
              </ListItemIcon>
              <ListItemText>Awaiting response</ListItemText>
            </MyMenuItem>
          </Link>
          {/* <Link to={`/${params.orgSlug}/inquiries/archived`}>
            <MyMenuItem selected={matches("/:orgId/inquiries/archived", pathname)}>
              <ListItemIcon>
                <InventoryIcon />
              </ListItemIcon>
              <ListItemText>Archived</ListItemText>
            </MyMenuItem>
          </Link> */}
          <ListSubheader>Inquiry settings</ListSubheader>
          <Link to={`/${params.orgSlug}/templates`}>
            <MyMenuItem selected={matches("/:orgId/templates/*", pathname)}>
              <ListItemIcon>
                <TemplateIcon />
              </ListItemIcon>
              <ListItemText>Templates</ListItemText>
            </MyMenuItem>
          </Link>
          <Link to={`/${params.orgSlug}/commands`}>
            <MyMenuItem selected={matches("/:orgId/commands/*", pathname)}>
              <ListItemIcon>
                <TerminalIcon />
              </ListItemIcon>
              <ListItemText>Commands</ListItemText>
            </MyMenuItem>
          </Link>
        </MenuList>
      </Box>
    </>
  );
};

export default AppMenu;
